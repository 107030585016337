import request from '@/utils/request'

import Uppy from '@uppy/core';
import Tus from '@uppy/tus';


/**
 *@Description: 上传服务
 *@MethodAuthor: AuthorName
 *@Date: 2023-02-08 16:20:42
 */

export async  function uploadBigApp(stsReq,file) {
    let resp = await getStsServer(stsReq);
    console.log(resp);
    let obj = resp.data;
    let data = {
        "auth_token": obj.accessToken,
        "path": obj.fastdfspath,
        "scene": obj.fastdfsscene
    }
    const uppy = new Uppy({
    }).use(Tus, {
        endpoint: '/group1/big/upload/', // 后端上传地址
    });
    uppy.setMeta(data)//这里是传递上传的认证参数,callback_url参数中 id为文件的ID,info 文转的基本信息json
    uppy.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file,
    });
    uppy.upload();
    return uppy;
}
export function getFileInfo(md5,token) {
    return request({
        url: "/group1/upload?md5="+md5+"&output=json"+"&auth_token="+token,
        method: 'get',
    })
}

export function addBigOss(stsReq,md5,uppyData) {
    let token = uppyData.store.state.meta.auth_token;
    return new Promise(function (resolve, reject) {
        getFileInfo(md5,token).then(res => {
            let queryForm = {
                group:"group1",
                mtype:"file",
                filename: getNameByPath(res.src),
                origname: getNameByPath(res.src),
                hash: res.md5,
                filesize: res.size,
                category: res.src.split(".")[1],
                url: res.url,
                storage: "fastdfs",
                bucket: res.scene,
                path: res.path,
                public: stsReq.public,
                source: stsReq.source,
                host_adress: res.domain,
                token:token
            };
            addOss(queryForm).then(ossResp => {
                if (ossResp.code === 0) {
                    resolve({
                        code: 0,
                        msg: getNameByPath(res.src) + "上传成功！",
                        time: formatDate(),
                        data: ossResp.data.data
                    });

                } else {
                    this.$message.error({message: '图片上传失败,请重新上传', duration: 2000});
                }
            });
        });
    });
}
export function uploadServer(stsReq,file) {
    return new Promise(function (resolve, reject) {
        //获取临时授权
        getStsServer(stsReq).then(response => {
            if (response.code == -1) {
                resolve({code: -1, msg: "获取token失败！", time: formatDate(), data: response.msg});
            }
            let obj = response.data;
            let token = obj.accessToken;
            let host = obj.fastdfshost;
            let group = obj.fastdfsgroup;
            let path = obj.fastdfspath;
            let scene = obj.fastdfsscene;
            let accessExpire = obj.accessExpire
            let uploadurl = obj.uploadurl;


            let data = {
                "file": file,
                "output": "json",
                "path": path,
                "scene": scene
            }

            let realUrl = uploadurl
            postFile(realUrl, data).then(res => {
                let respStr = JSON.stringify(res);
                if (respStr.indexOf("fail") == -1) {
                    //成功
                    let queryForm = {
                        group:group,
                        mtype:"file",
                        filename: getNameByPath(res.src),
                        origname: file.name,
                        user_id: stsReq.userid,
                        hash: res.md5,
                        filesize: res.size,
                        category: res.src.split(".")[1],
                        url: res.url,
                        storage: "fastdfs",
                        bucket: res.scene,
                        path: res.path,
                        public: stsReq.public,
                        source: stsReq.source,
                        host_adress: host,
                        token:token,
                        token_expire:accessExpire
                    };
                    addOss(queryForm).then(ossResp => {
                        if (ossResp.code === 0) {
                            resolve({
                                code: 0,
                                msg: file.name + "上传成功！",
                                time: formatDate(),
                                data: ossResp.data.data
                            });

                        } else {
                            this.$message.error({message: '图片上传失败,请重新上传', duration: 2000});
                        }
                    });

                } else {
                    //失败
                    resolve({code: -1, msg: fileName + "上传失败！", time: formatDate(), data: respStr});
                }
            });
        })
    });

}
export function getNameByPath(pathStr) {
    var fileNameIndex = pathStr.lastIndexOf("/") + 1;
    return pathStr.substr(fileNameIndex);
}
// 上传到fastdfs
export function postFile(url, data) {
    return request({
        url: url,
        headers: {'Content-Type': 'multipart/form-data'},
        method: 'post',
        data: data,
        needToken:false,
        timeout:500000
    })
}

//获取临时票据
export function getStsServer(row) {
    return request({
        url: '/dss/auth/token?platform=' + row.platform + '&type=' + row.type + '&source=' + row.source+"&public="+row.public,
        method: 'get',
        data: row,
        timeout: 500000,
    })
}

// 文件信息保存到dss
export function addOss(data) {
    return request({
        url: '/dss/oss/add',
        method: 'post',
        data: data,
        timeout: 500000,
    })
}

export function getDssInfo(path) {
    return request({
        url: '/dss/resource/get?path='+path,
        method: 'get',
    })
}
/**
 * 格式化日期
 * @param {string | number | Date} value 指定日期
 * @param {string} format 格式化的规则
 * @example
 * ```js
 * formatDate();
 * formatDate(1603264465956);
 * formatDate(1603264465956, "h:m:s");
 * formatDate(1603264465956, "Y年M月D日");
 * ```
 */
export function formatDate(value = Date.now(), format = "h:m:s") {
    const formatNumber = n => `0${n}`.slice(-2);
    const date = new Date(value);
    const formatList = ["Y", "M", "D", "h", "m", "s"];
    const res = [];
    res.push(date.getFullYear().toString());
    res.push(formatNumber(date.getMonth() + 1));
    res.push(formatNumber(date.getDate()));
    res.push(formatNumber(date.getHours()));
    res.push(formatNumber(date.getMinutes()));
    res.push(formatNumber(date.getSeconds()));
    for (let i = 0; i < res.length; i++) {
        format = format.replace(formatList[i], res[i]);
    }
    return format;
}
// 根据文件名后缀区分 文件类型
/*
 * @param: fileName - 文件名称
 * @param: 数据返回 1) 无后缀匹配 - false
 * @param: 数据返回 2) 匹配图片 - image
 * @param: 数据返回 3) 匹配 txt - txt
 * @param: 数据返回 4) 匹配 excel - excel
 * @param: 数据返回 5) 匹配 word - word
 * @param: 数据返回 6) 匹配 pdf - pdf
 * @param: 数据返回 7) 匹配 ppt - ppt
 * @param: 数据返回 8) 匹配 视频 - video
 * @param: 数据返回 9) 匹配 音频 - radio
 * @param: 数据返回 10) 其他匹配项 - other
 */
export function matchType(fileName) {

    // 后缀获取
    var suffix = '';
    // 获取类型结果
    var result = '';
    try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1];

    } catch (err) {
        suffix = '';

    }


    // fileName无后缀返回 false
    if (!fileName.includes(".")) {
        result = "";
        return result;
    }



    // 图片格式
    var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
    // 进行图片匹配
    result = imglist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '图片';
        return result;
    }
    ;
    // 匹配txt
    var txtlist = ['txt'];
    result = txtlist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '文档';
        return result;
    }
    ;
    // 匹配 excel
    var excelist = ['xls', 'xlsx'];
    result = excelist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '文档';
        return result;
    }
    ;
    // 匹配 word
    var wordlist = ['doc', 'docx'];
    result = wordlist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '文档';
        return result;
    }
    ;
    // 匹配 pdf
    var pdflist = ['pdf'];
    result = pdflist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '文档';
        return result;
    }
    ;
    // 匹配 ppt
    var pptlist = ['ppt'];
    result = pptlist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '文档';
        return result;
    }
    ;
    // 匹配 视频
    var videolist = ['mp4', 'm2v', 'mkv'];
    result = videolist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '媒体';
        return result;
    }
    ;
    // 匹配 音频
    var radiolist = ['mp3', 'wav', 'wmv'];
    result = radiolist.some(function (item) {
        return item == suffix;
    });
    if (result) {
        result = '媒体';
        return result;
    }
    // 其他 文件类型
    result = '其他';
    return result;
}