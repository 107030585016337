import request from "@/utils/request";

// 查询用户列表
export function listUser(query) {
  return request({
    url: "/user/list",
    method: "get",
    params: query,
  });
}
// 查询用户列表
export function getUserList(query) {
  return request({
    url: "/user/listex",
    method: "get",
    params: query,
  });
}

// 查询用户详细
export function getUser(id) {
  return request({
    url: "/user/get?id=" + id,
    method: "get",
  });
}

export function getUserByNo(no) {
  return request({
    url: "/user/get?no=" + no,
    method: "get",
  });
}

// 查询修改用户详细
export function getEditUser(userId) {
  return request({
    url: "/user/getForEdit?id=" + userId,
    method: "get",
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: "/user/add",
    method: "post",
    data: data,
  });
}

// 修改用户
export function updateUser(data) {
  return request({
    url: "/user/edit",
    method: "post",
    data: data,
  });
}

// 删除用户
export function delUser(userId) {
  return request({
    url: "/user/del",
    method: "post",
    params: { ids: userId },
  });
}

// 导出用户
export function exportUser(query) {
  return request({
    url: "/user/export",
    method: "get",
    params: query,
  });
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    id: userId,
    new_password: password,
  };
  return request({
    url: "/user/resetPwd",
    method: "post",
    data: data,
  });
}

// 解绑设备
export function unBindDevice(userId) {
  const data = {
    id: userId,
  };
  return request({
    url: "/user/unbinddevice",
    method: "post",
    data: data,
  });
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    id: userId,
    status: status,
  };
  return request({
    url: "/user/changeStatus",
    method: "post",
    data: data,
  });
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: "/user/current",
    method: "get",
  });
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: "/user/editProfile",
    method: "post",
    data: data,
  });
}

// 用户密码修改
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    old_password: oldPassword,
    new_password: newPassword,
  };
  return request({
    url: "/user/changePwd",
    method: "post",
    data: data,
  });
}

// 用户找回密码
export function findUserPwd(data) {
  return request({
    url: "/user/findPwd",
    method: "post",
    data: data,
  });
}

// 检查用户密码
export function checkUserPwd() {
  return request({
    url: "/user/checkPwd",
    method: "get",
  });
}

// 用户头像修改
export function changeUserAvatar(data) {
  return request({
    url: "/user/changeAvatar",
    method: "post",
    data: data,
  });
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: "/user/importTemplate",
    method: "get",
  });
}

// 同步角色岗位
export function syncRolePost() {
  return request({
    url: "/user/syncrolepost",
    method: "get",
  });
}

// 获取用户信息所有字段
export function getUserFields(userpool) {
  return request({
    url: "/user/fields?userpool=" + userpool,
    method: "get",
  });
}

// 审核
export function reviewUser(userpool, ids, review_status, review_reason) {
  const data = {
    userpool: userpool,
    ids: ids,
    review_status: review_status,
    review_reason: review_reason,
  };
  return request({
    url: "/user/review",
    method: "post",
    data: data,
  });
}

export function addSubordinate(userpool, user_id, subordinate_ids) {
  const data = {
    userpool: userpool,
    user_id: user_id,
    subordinate_ids: subordinate_ids,
  };
  return request({
    url: "/user/addsubordinate",
    method: "post",
    data: data,
  });
}

export function delSubordinate(userpool, user_id, subordinate_ids) {
  const data = {
    userpool: userpool,
    user_id: user_id,
    subordinate_ids: subordinate_ids,
  };
  return request({
    url: "/user/delsubordinate",
    method: "post",
    data: data,
  });
}
