<template>
  <div>
    <el-form
      :model="tableQueryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
      label-position="left"
      id="query_form"
      v-if="isQuery"
    >
      <el-form-item
        :label="item.title"
        :prop="item.field"
        v-for="(item, index) in queryParamsForms"
        :key="index"
        :label-width="item.title.length > 4 ? '100px' : '68px'"
        v-if="index > 1 ? !isMerge : true"
      >
        <el-input
          v-model="tableQueryParams[item.field]"
          placeholder="请输入"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
          v-if="!item.form_type || item.form_type * 1 === 1"
        />

        <el-select
          v-model="tableQueryParams[item.field]"
          placeholder="请选择"
          clearable
          size="small"
          v-if="item.form_type * 1 === 2"
        >
          <el-option
            :label="option_item[item.options_label]"
            :value="option_item[item.options_value]"
            v-for="(option_item, option_index) in item.options"
            :key="option_index"
          />
        </el-select>
        <el-date-picker
          v-model="dateRange"
          v-if="item.form_type * 1 === 3"
          size="small"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <slot name="query_qarams_item"></slot>
      <el-form-item v-if="queryParamsNodeList.length > 0 && isSearch">
        <el-button type="primary" icon="el-icon-search" @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>

        <el-button
          type="text"
          size="mini"
          @click="isMergeChange"
          v-if="queryParamsNodeList.length > 4"
          >{{ isMerge ? "展开" : "合并" }}
          <i :class="isMerge ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i
        ></el-button>
        <slot name="query-btn"></slot>
      </el-form-item>
    </el-form>
    <vxe-grid
      resizable
      ref="xGrid"
      :row-id="rowId"
      :id="id"
      :stripe="stripe"
      row-key
      border
      show-overflow
      highlight-hover-row
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
      @checkbox-change="checkboxChangeEvent"
      :tree-config="treeConfig"
      :highlight-current-row="highlightCurrentRow"
      :max-height="maxHeight"
      :height="height"
      @checkbox-all="checkboxChangeEvent"
      @sort-change="sortChange"
      :toolbar-config="toolbarConfig"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @cell-dblclick="Oncelldblclick"
      :custom-config="customConfig"
      :edit-config="editConfig"
      @radio-change="checkboxChangeEvent"
      @toolbar-button-click="toolbarButtonClickEvent"
      :export-config="exportConfig"
      :print-config="printConfig"
      :align="align"
      @current-change="currentChange"
      :row-config="{ isCurrent: true, isHover: true }"
      :checkbox-config="
        isOpenRow
          ? {
              trigger: 'row',
              highlight: true,
              range: true,
              checkMethod: checCheckboxkMethod3,
            }
          : {}
      "
    >
      <template #toolbar_buttons>
        <slot name="toolbar_btn_left"></slot>
        <div style="display: inline-block; float: right">
          <slot name="toolbar_btn_right"> </slot>
          <filter-btn
            v-on="$listeners"
            v-if="filterBtn"
            :list="tableTitleColumn"
            :filterTemplateList="filterTemplateList"
            :isFilterVisible="isFilterVisible"
          ></filter-btn>
          <columns-btn
            v-if="columnsBtn"
            :list="tableTitleColumn"
            @sortConfirm="sortConfirm"
          ></columns-btn>
          <sort-btn
            v-if="sortBtn"
            v-on="$listeners"
            :list="tableTitleColumn"
          ></sort-btn>
        </div>
      </template>
      <!-- 	<template #seq="{row}">
				<span>{{(queryParams[pageNumField] - 1) * queryParams[pageSizeField] + $index + 1}}</span>
			</template> -->
      <template #defaultopr="{ row, rowIndex }">
        <slot name="toolbar_btn_row" :row="row" :rowIndex="rowIndex"></slot>
      </template>
      <template
        v-slot:[item.slots.default]="{ row, rowIndex, columnIndex }"
        v-for="item in slotList"
      >
        <slot
          :name="item.slots.default"
          :row="row"
          :rowIndex="rowIndex"
          :columnIndex="columnIndex"
        ></slot>
        <template v-if="item.dictFormat">
          {{
            dictFormat({
              row,
              rowIndex,
              columnIndex,
              cellValue: row[item.field],
            })
          }}
        </template>
      </template>

      <template #empty>
        <span>
          <p>暂无数据</p>
        </span>
      </template>

      <template #pager>
        <vxe-pager
          v-if="pager"
          background
          size="small"
          :perfect="false"
          :loading="loading"
          :current-page.sync="tableQueryParams[pageNumField]"
          :page-size.sync="tableQueryParams[pageSizeField]"
          :total="total"
          @page-change="handlePageChange"
          style="margin-top: 15px"
        >
        </vxe-pager>
      </template>
    </vxe-grid>
  </div>
</template>

<script>
import filterBtn from "./toolbarBtn/filterBtn";
import columnsBtn from "./toolbarBtn/columnsBtn";
import sortBtn from "./toolbarBtn/sortBtn";
import draggable from "vuedraggable";
import moment from "moment";
import { deepClone } from "@/vform/utils/util";
export default {
  components: {
    filterBtn,
    columnsBtn,
    sortBtn,
    draggable,
  },
  props: {
    rowId: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
      default: "",
    },
    //是否显示 过滤按钮
    filterBtn: {
      type: Boolean,
      default: true,
    },
    // 是否显示列按钮
    columnsBtn: {
      type: Boolean,
      default: true,
    },
    //是否显示排序
    sortBtn: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    // titleColumn: {
    // 	type: Array,
    // 	default: () => []
    // },
    data: {
      type: Array,
      default: () => [],
    },
    toolbarConfig: {
      type: Object,
      default: () => {},
    },
    highlightCurrentRow: {
      type: Boolean,
      default: () => false,
    },
    //树形
    treeConfig: {
      type: Object,
      default: () => {},
    },
    //加载
    loading: {
      type: Boolean,
      default: false,
    },
    //查询
    queryParams: {
      type: Object,
      default: () => {},
    },
    //是否显示页码
    pager: {
      type: Boolean,
      default: true,
    },
    //页码字段
    pageNumField: {
      type: String,
      default: "pageNum",
    },
    //分页大小字段
    pageSizeField: {
      type: String,
      default: "pageSize",
    },
    //是否多选
    checkbox: {
      type: Boolean,
      default: true,
    },
    // 是否有操作栏
    operate: {
      type: Boolean,
      default: true,
    },
    //列表总条数
    total: {
      type: Number,
      default: 0,
    },
    //操作栏的宽度
    defaultoprWidth: {
      type: [String, Number],
      default: "180",
    },
    //是否显示斑马格
    stripe: {
      type: Boolean,
      default: false,
    },
    //最大高度
    maxHeight: {
      type: [String, Number],
      default: "",
    },
    //高度
    height: {
      type: [String, Number],
      default: "",
    },
    customConfig: {
      type: Object,
      default: () => {},
    },
    editConfig: {
      type: Object,
      default: () => {},
    },
    //是否单选
    radio: {
      type: Boolean,
      default: false,
    },
    //是否查询条件(多层嵌套的时候需要传)
    isQuery: {
      type: Boolean,
      default: true,
    },
    //导出
    exportConfig: {
      type: Object,
      default: () => {},
    },
    //打印
    printConfig: {
      type: Object,
      default: () => {},
    },
    apiUrl: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "left",
    },
    dictsData: {
      type: Object,
      default: () => {},
    },
    DsData: {
      type: Object,
      default: () => {},
    },
    filterTemplateList: {
      type: Array,
      default: () => [],
    },
    isFilterVisible: {
      type: Boolean,
      default: false,
    },
    // 是否展示搜索条件
    isSearch: {
      type: Boolean,
      default: true,
    },
    // 是否开启点击行选中
    isOpenRow: {
      type: Boolean,
      default: false,
    },
    // 表是否能选
    isNoCheck: {
      type: Boolean,
      default: true,
    },
    // 不选的行数组
    isNoCheckArrs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // tableColumn: [],
      tableTitleColumn: [],
      tableQueryParams: {},
      queryParamsForms: [],
      //是否合并
      isMerge: true,
      dateRange: [],
      queryParamsNodeList: [],
      dataList: [],
      is_dateRange: false,
      tableColumn: [],
      checkboxForm: {},
      operateForm: {},
      radioForm: {},
    };
  },

  created() {
    // this.getInit();
  },
  watch: {
    columns: {
      handler(val) {
        this.getInit();
      },
      //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
      //两者相同都是首次加载不会执行
      immediate: true,
      //deep:true,深度监听，可以监听对象属性
      deep: true,
    },
    data: {
      handler(val) {
        this.dataList = val;
      },
      //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
      //两者相同都是首次加载不会执行
      immediate: true,
      //deep:true,深度监听，可以监听对象属性
      deep: true,
    },
    queryParams: {
      handler(val) {
        this.tableQueryParams = val;
        // this.$nextTick(() => {

        //   let is_dialog = document.querySelector(".el-dialog__body ");
        //   if (!this.isQuery) return;
        //   var parentDiv = "";
        //   if (is_dialog) {
        //     parentDiv = document.querySelector(".el-dialog__body #query_form");
        //   } else {
        //     parentDiv = document.getElementById("query_form");
        //   }
        //   if (!parentDiv) {
        //     return;
        //   }
        //   // 获取所有的 <div> 子元素
        //   var childDivs = parentDiv.getElementsByTagName("div");
        //   let array = [...childDivs];

        //   // 遍历节点列表
        //   let index = 0;
        //   this.queryParamsNodeList = array.filter((node) => {
        //     // 检查节点是否为输入框
        //     if (
        //       node.className === "el-form-item el-form-item--small" &&
        //       node.innerText.indexOf("搜索重置") === -1
        //     ) {
        //       if (this.isMerge && index > 2) {
        //         node.style.display = "none";
        //       } else {
        //         node.style.display = "inline-block";
        //       }
        //       index++;
        //       return node;
        //     }
        //   });
        // });
        // 上面的方法多个表格在同一个页面会受影响,下面是解决方案之一
        this.$nextTick(() => {
          // 获取组件的根元素
          const rootElement = this.$el;

          // 检查是否是对话框中的表单
          let formElement;
          if (rootElement.querySelector(".el-dialog__body")) {
            formElement = rootElement.querySelector(
              ".el-dialog__body #query_form"
            );
          } else {
            formElement = rootElement.querySelector("#query_form");
          }

          if (!formElement) {
            return;
          }

          // 获取所有的 div 子元素
          const childDivs = formElement.querySelectorAll("div");
          const array = Array.from(childDivs);

          // 遍历节点列表
          let index = 0;
          this.queryParamsNodeList = array.filter((node) => {
            // 检查节点是否为输入框
            if (
              node.classList.contains("el-form-item") &&
              node.classList.contains("el-form-item--small") &&
              node.textContent.indexOf("搜索重置") === -1
            ) {
              if (this.isMerge && index > 2) {
                node.style.display = "none";
              } else {
                node.style.display = "inline-block";
              }
              index++;
              return true; // 返回 true 表示要保留这个节点
            }
            return false; // 返回 false 表示忽略这个节点
          });
        });
      },
      //此属性是控制是否首次加载就会执行，如果不写immediate，或者写immediate：false，
      //两者相同都是首次加载不会执行
      immediate: true,
      //deep:true,深度监听，可以监听对象属性
      deep: true,
    },
  },
  computed: {
    // tableColumn: function () {
    //     let list = this.tableTitleColumn;
    //     let array = [];
    //     list.forEach((o, i) => {
    //         let isCheck = o.isCheck
    //             ? JSON.parse(JSON.stringify(o.isCheck))
    //             : "";

    //         delete o.form_type;
    //         delete o.options;
    //         delete o.options_label;
    //         delete o.options_value;
    //         delete o.slotsFun;
    //         delete o.isCheck;
    //         delete o.is_query;
    //         delete o.dateRange;
    //         if (o.dictFormat && !o.slots) {
    //             o.slots = { default: o.field };
    //         }
    //         if (isCheck) {
    //             array.push(o);
    //         }
    //     });
    //     if (this.checkbox) {
    //         array.unshift({
    //             type: "checkbox",
    //             width: 60,
    //             fixed: "left",
    //         });
    //     }
    //     if (this.radio) {
    //         array.unshift({
    //             type: "radio",
    //             width: 60,
    //             fixed: "left",
    //         });
    //     }
    //     if (this.operate) {
    //         array.push({
    //             field: "",
    //             title: "操作",
    //             width: this.defaultoprWidth,
    //             fixed: "right",
    //             align: "center",
    //             slots: {
    //                 default: "defaultopr",
    //             },
    //         });
    //     }

    //     return array;
    // },
    slotList: function () {
      let list = [];
      this.columns.forEach((item, index) => {
        let obj = this.getSlots(item);
        obj ? list.push(obj) : "";
        if (item.children) {
          item.children.forEach((child) => {
            let obj1 = this.getSlots(child);
            obj1 ? list.push(obj1) : "";
          });
        }
      });
      return list;
    },
  },
  methods: {
    //数据重置
    getInit() {
      this.queryParamsForms = [];
      this.tableTitleColumn = [];
      for (let i = 0; i < this.columns.length; i++) {
        let obj = this.columns[i];
        if (obj.is_query) {
          this.queryParamsForms.push(obj);
          if (obj.dateRange) {
            this.dateRange = obj.dateRange;
            this.is_dateRange = true;
          }
          // if(this.apiUrl&&Object.keys(!this.queryParams).length){

          // }
        }
        if (
          obj.title &&
          obj.title.indexOf("时间") > -1 &&
          !obj.form_type &&
          !obj.dictType
        ) {
          obj.form_type = 3;
        }
        if (obj.type === "checkbox") {
          obj.fixed = "left";
          this.checkboxForm = this.deepCopy(obj);
        }
        if (obj.type === "radio") {
          this.radioForm = this.deepCopy(obj);
        }
        if (obj.title === "操作") {
          this.operateForm = this.deepCopy(obj);
          this.operateForm.slots.default = "defaultopr";
        }
        // (typeof obj.visible !== "boolean" || obj.visible) &&
        if ((obj.title || obj.type === "seq") && obj.title !== "操作") {
          obj.isCheck = typeof obj.isCheck === "boolean" ? obj.isCheck : true;
          this.tableTitleColumn.push(obj);
        }
      }
      this.getTableColumn();
    },

    getTableColumn(is_sort) {
      let array = [];
      for (let i = 0; i < this.tableTitleColumn.length; i++) {
        let obj = this.tableTitleColumn[i];
        if (!is_sort) {
          if (obj.dictType) {
            if (!obj.dictData) {
              //是否传入数据字典
              if (
                this.dictsData &&
                Object.keys(this.dictsData).length &&
                this.dictsData[obj.dictType] &&
                this.dictsData[obj.dictType].length
              ) {
                let dictsData = this.dictsData[obj.dictType]
                  ? this.dictsData[obj.dictType]
                  : [];
                obj.dictData = dictsData;
                obj.options_label = "value";
                obj.options_value = "key";
                obj.form_type = 2;
              } else {
                this.getDicts(obj.dictType).then((response) => {
                  if (response.code == 0) {
                    obj.dictData = response.data;
                    if (!obj.options && obj.dictData) {
                      obj.options = obj.dictData.values || obj.dictData;
                      obj.options_label = "value";
                      obj.options_value = "key";
                      obj.form_type = 2;
                    }
                    this.dataList = [].concat(this.dataList);
                  }
                });
              }
            } else {
              if (!obj.options) {
                obj.options = obj.dictData.values || obj.dictData;
                obj.options_label = "value";
                obj.options_label = "key";
                obj.form_type = 2;
              }
            }
          } else if (obj.dsEnabled) {
            var dstid = obj.dsConf_dstid;
            var dstattr = obj.dsConf_dstattr;
            if (dstid != null && dstid != "") {
              //区分多项和单项默认值

              //多行数据
              var optionlist = [];
              var dsConf_dst_label = obj.dsConf_dst_label || "";
              var dsConf_dst_value = obj.dsConf_dst_value || "";
              var labelKey = obj.options_label || "label";
              var valueKey = obj.options_value || "value";
              if (labelKey == "") {
                labelKey = "label";
              }
              if (valueKey == "") {
                valueKey = "label";
              }

              if (dsConf_dst_label == "" || dsConf_dst_value == "") {
                console.warn("指定数据源签名或指定数据源值名为空,忽略");
                continue;
              }
              dsConf_dst_label = dsConf_dst_label.split(":")[0];
              dsConf_dst_value = dsConf_dst_value.split(":")[0];

              for (let ik in this.DsData["ds" + dstid]) {
                let dsrow = this.DsData["ds" + dstid][ik];
                let opt = {};
                opt[labelKey] = dsrow[dsConf_dst_label];
                opt[valueKey] = dsrow[dsConf_dst_value];

                optionlist.push(opt);
              }

              obj.options = deepClone(optionlist);
              obj.options_label = labelKey;
              obj.options_value = valueKey;
              obj.form_type = 2;
            }
          }
        }

        // let col = this.tableTitleColumn[i];
        // let isCheck = col.isCheck
        //     ? JSON.parse(JSON.stringify(col.isCheck))
        //     : "";

        // delete col.form_type;
        // delete col.options;
        // delete col.options_label;
        // delete col.options_value;
        // delete col.slotsFun;
        // delete col.isCheck;
        // delete col.is_query;
        // delete col.dateRange;
        if (obj.dictFormat && !obj.slots) {
          obj.slots = { default: obj.field };
        }
        if (obj.isCheck) {
          array.push(obj);
        }
      }
      //判断是否已经有checkbox列
      if (Object.keys(this.checkboxForm).length > 0) {
        array.unshift(this.checkboxForm);
      } else if (this.checkbox && !Object.keys(this.checkboxForm).length) {
        array.unshift({
          type: "checkbox",
          width: 60,
          fixed: "left",
        });
      }
      if (Object.keys(this.radioForm).length > 0) {
        array.unshift(this.radioForm);
      } else if (this.radio) {
        array.unshift({
          type: "radio",
          width: 60,
          fixed: "left",
        });
      }
      if (Object.keys(this.operateForm).length > 0) {
        array.push(this.operateForm);
      } else if (this.operate && Object.keys(this.operateForm).length === 0) {
        array.push({
          field: "",
          title: "操作",
          width: this.defaultoprWidth,
          fixed: "right",
          align: "center",
          slots: {
            default: "defaultopr",
          },
        });
      }
      this.tableColumn = array;
    },
    // get

    getSlots(child) {
      if (child.title !== "操作") {
        if (child.slots && typeof child.slots.default === "string") {
          return child;
        } else if (child.dictFormat) {
          child.slots = {
            default: child.field,
          };
          return child;
        }
      }
    },
    dictFormat(e) {
      const col = this.tableColumn[e.columnIndex];

      if (col && col.dictData) {
        const values = e.cellValue ? e.cellValue.split(",") : [];
        const labels = [];
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < col.dictData.values.length; j++) {
            const item = col.dictData.values[j];
            if (v == item.key) {
              labels.push(item.value);
              break;
            }
          }
        }

        return labels.join(",");
      }

      return e.cellValue;
    },
    isMergeChange() {
      this.isMerge = !this.isMerge;

      this.queryParamsNodeList.forEach((item, index) => {
        if (this.isMerge && index > 2) {
          item.style.display = "none";
        } else {
          item.style.display = "inline-block";
        }
      });
    },
    /** 搜索按钮操作 */
    handleQuery(value) {
      this.tableQueryParams[this.pageNumField] = 1;
      let obj = {};
      if (this.is_dateRange) {
        if (this.dateRange) {
          obj = this.addDateRange(this.tableQueryParams, this.dateRange);
        } else {
          obj = this.deepCopy(this.tableQueryParams);
          if (obj.beginTime) {
            delete obj.beginTime;
          }
          if (obj.endTime) {
            delete obj.endTime;
          }
        }
      } else {
        obj = this.deepCopy(this.tableQueryParams);
      }

      if (value == "qc_resultData") obj["qc_resultData"] = true;
      this.$emit("handleQuery", obj);
    },
    /** 重置按钮操作 */
    resetQuery() {
      if (this.is_dateRange) {
        this.dateRange = [
          moment().format("yyyy-MM-DD"),
          moment().add(1, "days").format("yyyy-MM-DD"),
        ];
      }

      this.resetForm("queryForm");
      this.handleQuery("qc_resultData");
    },
    // 翻译
    valueFormat(data, value) {},

    sortConfirm(e) {
      this.tableTitleColumn = this.deepCopy(e.data);
      this.getTableColumn(true);
    },
    sortChange(column, property, order, sortBy, sortList) {
      this.$emit("sort-change", {
        column,
        property,
        order,
        sortBy,
        sortList,
      });
    },
    checkboxChangeEvent(e) {
      if (this.radio) {
        this.$emit("radio-change", e);
      } else {
        this.$emit("checkbox-change", e);
      }

      // this.ids = records.map((item) => item.id);
      // this.single = records.length != 1;
      // this.multiple = !records.length;
    },
    //查看
    Oncelldblclick(row) {
      this.$emit("cell-dblclick", row);
    },

    toggleExpandChangeEvent(e) {
      this.$emit("toggle-tree-expand", e);
    },
    handlePageChange(e) {
      this.$emit("page-change", e);
    },
    toolbarButtonClickEvent(e) {
      this.$emit("toolbar-button-click", e);
    },
    currentChange(e) {
      this.$emit("current-change", e);
    },
    // 清除选中
    clearSelect() {
      // this.$refs.xGrid.clearRadioRow();
      this.$refs.xGrid.clearCheckboxRow();
    },
    // isCheckbox
    checCheckboxkMethod3(row) {
      if (this.isNoCheck) {
        if (this.isNoCheckArrs.length) {
          return !this.isNoCheckArrs.some((item) => {
            return item.id == row.row.id;
          });
        }
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.vxe-tools--operate {
  .vxe-button {
    border: none !important;

    &:hover {
      border: none !important;
      box-shadow: none;
    }

    &:focus {
      border: none !important;
      color: #1890ff;
      box-shadow: none;
    }
  }
}
</style>
