<template>
  <div>
    <el-upload
      class="upload-demo"
      :accept="accept"
      action="/file/upload"
      :headers="uploadHeaders"
      :data="uploadData"
      :multiple="multiple"
      :limit="limit"
      :size="size"
      :file-list="imageList"
      :show-file-list="multiple"
      :disabled="disabled||readonly"
      :before-upload="handleBeforceUpload"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :on-exceed="handleUploadExceed"
      :on-remove="handleUploadRemove"
      :on-preview="handlePreview"
      :list-type="listType">
      <i v-if="!(disabled||readonly) && multiple && listType=='picture-card'" slot="default" class="el-icon-plus"></i>
      <div v-else-if="!multiple && imageList.length > 0" :style="'background-image: url('+imageList[0].url+')'" class="image">
        <div class="delete" @click.stop="handleClear">
          <i style="font-size: 16px;" class="el-icon-close"></i>
        </div>
      </div>
      <el-button v-else-if="!(disabled||readonly)" size="small" type="primary">点击上传</el-button>
      <div v-if="!(disabled||readonly)" slot="tip" class="el-upload__tip">{{tip}}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true" title="图片预览" @closed="handleDialogClose">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getAccessToken } from '@/utils/tokenTool'
import { uploadFile } from "@/api/file/file";

export default {
  name: 'image-upload',
  props: {
    value: {
      type: String,
      default: () => { return null }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    readonly: {
      type: Boolean,
      default: () => { return false }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    accept: {
      type: String,
      default: () => { return 'image/png, image/jpeg' }
    },
    tip: {
      type: String,
      default: () => { return '只能上传jpg/png文件，且不超过500kb' }
    },
    multiple: {
      type: Boolean,
      default: () => { return true }
    },
    limit: {
      type: Number,
      default: () => { return undefined }
    },
    listType: {
      type: String,
      default: () => { return 'picture-card' }
    },
    category: {
      type: String,
      default: () => { return '' }
    },
    public: {
      type: String,
      default: () => { return "1" }
    }
  },
  data() {
    return {
      uploadHeaders: {
        "Authorization": getAccessToken()
      },
      uploadData: {
        "category": this.category,
        "public": this.public
      },
      imageList: [],
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  created() {
    const imageList = []
    if (this.value) {
      const sps = this.value.split(',')
      for (let i = 0; i < sps.length; i++) {
        imageList.push({
          "url": sps[i]
        })
      }
    }
    this.imageList = imageList
  },
  methods: {
    handleBeforceUpload(file) {
      if (this.accept) {
        const sps = this.accept.split(',')
        for (let i = 0; i < sps.length; i++) {
          const s = sps[i].trim().toLowerCase()
          if (s == file.type) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    handleUploadSuccess(response, file, fileList) {
      //console.log(response, file, fileList)
      let value = undefined
      if (this.multiple) {
        value = fileList.map((v) => v.response && v.response.data && v.response.data.url || v.url).join(',') // 
      } else {
        value = response.data.url
      }
      //console.log(value)
      this.$emit('input', value)
      this.$emit('change', value)
    },
    handleUploadError(err) {
      if (err && (err.message.indexOf('larger than') > 0 || err.message.indexOf('413') > 0)) {
        this.msgError("上传文件失败，文件大小超过限制");
      } else {
        this.msgError("上传文件失败");
      }
    },
    handleUploadExceed() {
      this.msgError("只能上传一个文件，请删除后重新上传");
    },
    handleUploadRemove(file, fileList) {
      const value = fileList.map((v) => v.response && v.response.data && v.response.data.url || v.url).join(',') // 
      //console.log(value)
      this.$emit('input', value)
      this.$emit('change', value)
    },
    handlePreview(file) {
      //console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDialogClose() {
      this.dialogImageUrl = ''
    },
    handleClear() {
      this.$emit('input', '')
      this.$emit('change', '')
    }
  },
  watch: {
    value(val) {
      const imageList = []
      if (val) {
        const sps = val.split(',')
        for (let i = 0; i < sps.length; i++) {
          imageList.push({
            "url": sps[i]
          })
        }
      }
      this.imageList = imageList
      console.log('shit', val)
    }
  }
}
</script>
<style scoped>
.image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.image .delete {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image .delete i {
  display: none;
}
.image:hover .delete i {
  display: inline;
}
</style>